<template>
    <v-row justify="center" class="py-5" style="height: 100%; min-width: 240px;" align="center">
        <v-col style="text-align: center" cols="10" sm="8" md="6" lg="4">

            <!-- TODO: link to view account -->
            <!-- TODO: show featured products; allow organization to select which products are on front page, or set it up with special banners with a page builder, or something like that; and/or we can show links to view account, go to brand's homepage (link from their brandprofile), etc. we don't need to check if uesr is authenticated, because if they try to access a feature that is for authenticated users only they will get redirected to login page; -->
            <!-- TODO: the list of products needs to be styled better; if there's a product image then show the image and product name in a little card maybe -->
            <!-- TODO: show the cancel button differently, and make it clear that it empties the entire cart ... and not just goes back to last page... maybe there need to be separate buttons for these two things, properly labeled -->
            <template v-if="isViewReady && cart">
                <CheckoutProgress :shipping="cart.require.require_shipping" :billing="cart.require.require_billing"/>
            </template>

            <!-- <p class="text-caption text-end">
            </p> -->
            <v-card v-if="isViewReady && cart" class="mt-6">
                <v-app-bar :color="primaryColor" dark flat dense>
                    <v-app-bar-title>Checkout</v-app-bar-title>
                    <v-spacer/>
                    <v-btn text @click="cancel">Cancel</v-btn>
                </v-app-bar>
                <v-card-text>
                    <CheckoutSelectAccount @selected="selectAccount"></CheckoutSelectAccount>
                    <v-simple-table class="finalize-order-form" v-if="signupCheckbox">
                        <template #default>
                            <tbody>
                                <!-- TODO: do we need the cart.require.require_name/email here? if 'signupCheckbox' is true we already need to collect them to create a new account for the user, whether it's a guest account or personal -->
                                <tr v-if="cart.require.require_name || signupCheckbox">
                                    <th>Name</th>
                                    <td><v-text-field v-model="name" :error-messages="inputError.name" dense solo/></td>
                                </tr>
                                <tr v-if="cart.require.require_email || signupCheckbox">
                                    <th>Email</th>
                                    <td><v-text-field v-model="email" :error-messages="inputError.email" dense solo/></td>
                                    <!-- TODO: explain why we need this -- to send receipt, to send delivery notification, etc. we can show the reasons based on the require_* items , or maybe server could also provide reason codes for us to display-->
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
                <v-card-actions v-if="cart.items.length > 0">
                    <v-spacer/>
                    <v-btn :style="primaryButtonStyle" @click="finalize">Continue</v-btn>
                </v-card-actions>
                <!-- TODO: subtotal, shipping/taxes, total -->
                <!-- <v-form @submit.prevent="checkout" @keyup.enter.prevent="checkout">
                    <v-simple-table>
                        <template #default>
                            < ! - - <thead>
                                <tr>
                                    <th style="text-align: start; width: 70%;">Where should we email the receipt?</th>
                                    <th style="text-align: end;"></th>
                                </tr>
                            </thead> - - >
                            <tbody>
                                <tr>
                                    <td style="text-align: start; vertical-align: top;" class="pt-6">
                                        <v-text-field label="Enter your email address" placeholder="" outlined dense persistent-placeholder v-model="email" ref="emailInput" :color="primaryColor" :error-messages="emailError">
                                            <template v-slot:prepend>
                                                <font-awesome-icon icon="envelope" fixed-width/>
                                            </template>
                                        </v-text-field>
                                    </td>
                                    <td style="text-align: end; vertical-align: top;" class="pt-6">
                                        <v-btn :style="primaryButtonStyle" @click="checkout">Continue</v-btn>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-form> -->
            </v-card>
            <!-- TODO:  if there's an error loading the cart, show temporary error: -->
            <v-card elevation="2" v-if="isViewReady && !cart">
                <v-app-bar color="red darken-2" dark flat dense>
                    <v-app-bar-title>Maintenance</v-app-bar-title>
                </v-app-bar>
                <v-card-text class="pt-8">
                    <p>The server is temporarily unavailable. We are going to automatically retry until it's ready. Please wait...</p>
                    <!-- <p>Return to the last page and contact the emergency home for details.
                    <v-btn :to="loginRoute" elevation="2" :style="primaryButtonStyle" class="my-6" outlined>Sign in</v-btn>
                    <p>No account yet? <a :href="mainWebsiteURL">Sign up</a></p> -->
                    <!-- <p class="mt-8"><a :href="mainWebsiteURL">Learn more about Unicorn Springs</a></p> -->
                </v-card-text>
            </v-card>
            <!-- <template v-if="!isAuthenticatedReady">
                <v-row style="height: 100%" align="center" justify="center">
                    <div class="app-splash-loader"></div>
                </v-row>
            </template>
            <template v-if="isAuthenticatedReady">
                <template v-if="isAuthenticated">
                    <v-row style="height: 100%" align="center" justify="center">
                        <div class="app-splash-loader"></div>
                        <p class="mt-6">
                        <router-link :to="{ name: 'dashboard' }">Continue to dashboard</router-link>
                        </p>
                    </v-row>
                </template>
                <template v-if="!isAuthenticated">
                    <LoginCard/>
                </template>
            </template> -->
        </v-col>
    </v-row>
</template>

<style>
/* regular input height is 56px; dense input height is 40px */
/* font awesome icon width is 16px, while append/prepend-inner width is 20px */
.v-input .v-input__prepend-inner {
    margin-left: 2px !important; /* (20px placeholder width - 16px icon width) / 2 */
    padding-left: 2px !important;
    margin-top: 12px !important; /* (40px input height - 16px icon height) / 2 */
    margin-bottom: 12px !important;
    padding: 0px;
}
.v-input .v-input__prepend-outer {
    margin-left: 2px !important; /* (20px placeholder width - 16px icon width) / 2 */
    padding-left: 2px !important;
    margin-top: 12px !important; /* (40px input height - 16px icon height) / 2 */
    margin-bottom: 12px !important;
    padding: 0px;
}
/* remove messages area from inputs */
.v-input.v-input--checkbox.hide-messages .v-messages {
    display: none;
}
/* align table headings and content to top */
.finalize-order-form th {
    vertical-align: top;
    padding-top: 6px !important;
    padding-bottom: 4px !important;
}
.finalize-order-form td {
    vertical-align: top;
    padding-top: 6px !important;
    padding-bottom: 4px !important;
}
/* remove hover background change from data table */
.v-data-table tr:hover {
    background-color: #ffffff !important;
}
</style>

<script>
import { mapState, mapGetters } from 'vuex';
// import { toMillis } from '@libertyio/time-util-js';
import {
    SETTING_REGISTRATION_MODE,
    REGISTRATION_MODE_CONNECT,
} from '@/sdk/constants';
import { isValidEmail } from '@/sdk/input';
// import CheckoutItemQuantity from '@/components/CheckoutItemQuantity.vue';
// import CheckoutItemCustomAmount from '@/components/CheckoutItemCustomAmount.vue';
import CheckoutProgress from '@/components/CheckoutProgress.vue';
import CheckoutSelectAccount from '@/components/CheckoutSelectAccount.vue';
// import TextButton from '@/components/TextButton.vue';

export default {
    components: {
        CheckoutProgress,
        CheckoutSelectAccount,
        // CheckoutItemQuantity,
        // CheckoutItemCustomAmount,
        // TextButton,
    },
    data: () => ({
        product: null,
        isViewReady: false,
        account: null,
        accountList: null,
        signupCheckbox: false,
        email: null,
        emailError: null,
        emailErrorTimeout: null,
        checkoutTimestamp: null,
        stripeAccount: null,
        stripePaymentIntent: null,
        paymentAmount: null,
        paymentCurrency: null,
        registrationMode: null,
        inputError: {
            name: null,
            email: null,
            billing_address: null,
            shipping_address: null,
        },
        inputErrorTimeout: {
            name: null,
            email: null,
            billing_address: null,
            shipping_address: null,
        },
        // mode: 'prompt', // 'prompt' shows line items and continue button, 'email' prompts for email
    }),
    computed: {
        ...mapState({
            isAuthenticatedReady: (state) => state.isAuthenticatedReady,
            session: (state) => state.session,
            user: (state) => state.user,
            brandselector: (state) => state.brandselector,
            brandprofile: (state) => state.brandprofile,
            brandNotFoundError: (state) => state.brandNotFoundError,
            focus: (state) => state.focus,
            cart: (state) => state.cart,
        }),
        ...mapGetters({
            primaryColor: 'primaryColor',
            primaryButtonStyle: 'primaryButtonStyle',
            primaryIconStyle: 'primaryIconStyle',
        }),
        isAuthenticated() {
            return this.session.isAuthenticated;
        },
        frontLink() {
            let link;
            if (this.brandselector === 'brandprofile') {
                link = { name: 'brand-front', params: { brandprofile: this.brandprofile } }; // TODO: should the name be 'brand-search' ? we don't have a route for that defined right now in router.js
            } else {
                link = { name: 'front' };
            }
            return link;
        },
        searchLink() {
            let link;
            if (this.brandselector === 'brandprofile') {
                link = { name: 'search', params: { brandprofile: this.brandprofile } }; // TODO: should the name be 'brand-search' ? we don't have a route for that defined right now in router.js
            } else {
                link = { name: 'search' };
            }
            return link;
        },
        shippingLink() {
            let link;
            if (this.brandselector === 'brandprofile') {
                link = { name: 'brand-checkout-shipping', params: { brandprofile: this.brandprofile } };
            } else {
                link = { name: 'main-checkout-shipping' };
            }
            return link;
        },
        billingLink() {
            let link;
            if (this.brandselector === 'brandprofile') {
                link = { name: 'brand-checkout-shipping', params: { brandprofile: this.brandprofile } };
            } else {
                link = { name: 'main-checkout-shipping' };
            }
            return link;
        },
        finalizeLink() {
            let link;
            if (this.brandselector === 'brandprofile') {
                link = { name: 'brand-checkout-finalize', params: { brandprofile: this.brandprofile } };
            } else {
                link = { name: 'main-checkout-finalize' };
            }
            return link;
        },
        paymentLink() {
            let link;
            if (this.brandselector === 'brandprofile') {
                link = { name: 'brand-checkout-payment', params: { brandprofile: this.brandprofile } };
            } else {
                link = { name: 'main-checkout-payment' };
            }
            return link;
        },
        isCheckoutFormComplete() {
            return typeof this.email === 'string' && this.email.length > 0 && isValidEmail(this.email);
        },
        registrationModeConnect() {
            return this.registrationMode === REGISTRATION_MODE_CONNECT;
        },
        /*
        isCreateAccountFormComplete() {
            return this.createAccountName && this.createAccountType;
        },
        registrationModeConnect() {
            return this.registrationMode === REGISTRATION_MODE_CONNECT;
        },
        registrationModeOpen() {
            return this.registrationMode === REGISTRATION_MODE_OPEN;
        },
        registrationModeRestricted() {
            return this.registrationMode === REGISTRATION_MODE_RESTRICTED;
        },
        registrationModeModerated() {
            return this.registrationMode === REGISTRATION_MODE_MODERATED;
        },
        registrationModeClosed() {
            return this.registrationMode === REGISTRATION_MODE_CLOSED;
        },
        */
    },
    watch: {
        isAuthenticatedReady(newValue, oldValue) {
            if (newValue && !oldValue) {
                this.init();
            }
        },
        brandprofile(newValue, oldValue) {
            if (newValue && newValue !== oldValue) {
                this.init();
            }
        },
        focus() {
            if (this.brandprofile) {
                this.init();
            }
        },
        createAccountForm(newValue) {
            if (newValue) {
                this.createAccountName = '';
                this.$nextTick(() => {
                    setTimeout(() => { this.$activateInput('createAccountNameInput'); }, 1);
                });
            }
        },
    },
    methods: {
        async clearCart() {
            try {
                this.$store.commit('loading', { clearCart: true });
                const response = await this.$client.site(this.brandprofile).cart.delete();
                if (response.isDeleted) {
                    this.$store.commit('cart', response.cart);
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Cannot clear shopping cart' });
                }
            } catch (err) {
                console.error('clearCart failed', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Cannot clear shopping cart', message: 'Please try again' });
            } finally {
                this.$store.commit('loading', { clearCart: false });
            }
        },
        async cancel() {
            await this.clearCart();
            this.$nav.replace(this.searchLink);
        },
        async finalize() {
            try {
                this.error = false;
                // 'payment_intent', 'payment_intent_client_secret', and 'redirect_status=succeeded'
                this.$store.commit('loading', { finalize: true });
                // send a 'finalize' request with no parameters to get requirements for the items currently in the cart
                const response = await this.$client.site(this.brandprofile).cart.finalize({});
                if (response) {
                    this.$store.commit('cart', response);
                } else {
                    this.$store.commit('cart', null);
                }
                if (this.cart.status === 'ready') {
                    // TODO: send the user to paymentLink instead, and have an indication there that user is going to do a guest checkout, or is signed in and has account X selected, and a button to use a different account would take user to checkoutAccountLink ?
                    // this.$nav.push(this.checkoutAccountLink);
                    this.$nav.push(this.paymentLink);
                    return;
                }

                // finalize API either responds with an error (400, 401, 403) or a status of 'ready' or 'finalize';
                // if (this.cart.status === 'finalize') {
                if (this.cart.finalize.includes('require_signup')) {
                    this.$bus.$emit('snackbar', { type: 'info', headline: 'At least one item in the cart requires an account' });
                    return;
                }
                if (this.cart.finalize.includes('require_shipping')) {
                    this.$nav.push(this.shippingLink);
                    return;
                }
                if (this.cart.finalize.includes('require_billing')) {
                    this.$nav.push(this.billingLink);
                    return;
                }
                // this.cart.finalize.includes('require_name') || this.cart.finalize.includes('require_email')
                this.$nav.push(this.finalizeLink);
                // return;
                // }
            } catch (err) {
                console.error('finalize failed', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { finalize: false });
            }
        },
        async init() {
            await this.$store.dispatch('loadCart');
            this.isViewReady = true;
            // this.loadAuthenticationModeSetting();
        },
        login() {
            this.$nav.push({ name: 'brand-login', query: { next: this.$router.currentRoute.fullPath } });
        },
        selectAccount(accountId) {
            if (this.$route.query.accountId !== accountId) {
                this.$router.replace({ name: this.$route.name, params: this.$route.params, query: { ...this.$route.query, accountId, t: Date.now() } });
            }
        },
        async loadSetting(name) {
            const progressLabel = `loadSetting__${name}`;
            try {
                this.$store.commit('loading', { [progressLabel]: true });
                const result = await this.$client.site(this.brandprofile).setting.get({ name });
                if (result?.content) {
                    return result.content;
                }
                return null;
            } catch (err) {
                console.error('loadSetting failed', err);
                return null;
            } finally {
                this.$store.commit('loading', { [progressLabel]: false });
            }
        },
        async loadRegistrationModeSetting() {
            this.registrationMode = await this.loadSetting(SETTING_REGISTRATION_MODE);
        },
    },
    mounted() {
        // if (this.hostname === window.location.hostname) {
        //     // special case for front page of main site, it's not a storefront
        // }
        // if (this.isAuthenticatedReady) {
        //     this.init();
        // }
        if (this.brandprofile) {
            this.init();
        }
    },
};
</script>
